<template>
    <div>
        <div class="box">
            <el-page-header @back="$router.go(-1)" :content="content"></el-page-header>
        </div>
        <el-card class="mt20 bbb">
            <el-form label-position="left" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item :label="$t('QA.title')" prop="title">
                    <div>
                        {{ruleForm.title}}
                    </div>
                </el-form-item>
                <el-form-item :label="$t('QA.question')" prop="question">
                    <div class="word_break" v-html="ruleForm.question">
                    </div>
                </el-form-item>
                <el-form-item :label="$t('QA.answer')" prop="answer">
                    <div v-if="load">
                        <SummerNote :id="'summernote' + new Date().getTime()" v-model="ruleForm.answer" />
                    </div>
                </el-form-item>
            </el-form>
            <el-button  class="btn" type="primary" @click="submitForm('ruleForm')">{{$t('btnTip.submit')}}</el-button>
        </el-card>
    </div>
</template>
<script>
import SummerNote from '@/components/summernote';
import { getQADetail,editQA } from '@/api/index'
export default {
    name:'editQA',
    data(){
        return {
            ruleForm:{
               answer:''
            },
            rules: {
                answer: [
                    { required: true, message: this.$t('QA').answer, trigger: 'blur' }
                ],
            },
            load:false
        }
    },
    components:{SummerNote},
    computed:{
        id(){
			return Number.isNaN(Number(this.$route.query.id)) ? undefined : Number(this.$route.query.id)
        },
        content(){
			return this.id === undefined ? this.$t('btnTip').add : this.$t('btnTip').edit
		},
    },
    methods:{
        async submitForm(formName) {
            await this.$refs[formName].validate()
            await editQA({id:this.ruleForm.id,answer:this.ruleForm.answer})
            this.$router.go(-1)
        },
        async get_info(){
            const information = await getQADetail(this.id)
            this.ruleForm = {...information}
            this.load=true
        },
    },
    async created(){
        if(this.id === undefined) return
		this.get_info()
    },

}
</script>
<style land="scss" scoped>
    .el-date-editor{
        margin-right:10px;
    }
    .bbb{
	position: relative;
	padding-bottom: 50px;
    }
    .bbb .btn{
        position: absolute;
        bottom:10px;
        right: 20px;
    }
</style>
